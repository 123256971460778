import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionLineVersion } from './ProductionLineVersion'
import { DataSourceStore } from './DataSource'
import { OperatorAssignmentStore } from './OperatorAssignment'

export class WorkStationLocation extends Model {
  static backendResourceName = 'work_station_location'

  @observable id = null
  @observable code = null
  @observable x = 0
  @observable y = 0
  @observable width = 0
  @observable height = 0
  @observable singleUser = false

  @observable ordering = 0
}

export class WorkStationLocationStore extends Store {
  Model = WorkStationLocation
  static backendResourceName = 'work_station_location'
}

export class WorkStation extends Model {
  static backendResourceName = 'work_station'

  @observable id = null
  @observable code = null
  @observable name = ''
  @observable color = null
  @observable ordering = 0
  @observable defaultFte = null
  @observable automationDate = null

  @observable singleUser = false
  @observable productionStrategy = 'together'
  @observable maintenanceStrategy = 'together'

  @observable _adding = false // flag for automatically opening the edit modal

  relations() {
    return {
      productionLineVersion: ProductionLineVersion,
      locations: WorkStationLocationStore,
      dataSources: DataSourceStore,
      assignments: OperatorAssignmentStore,
    }
  }

  casts() {
    return {
      automationDate: Casts.date,
    }
  }

  start() {
    return this.wrapPendingRequestCount(this.api.post(`${this.urlRoot()}start/`, { code: this.code }))
  }
}

export class WorkStationStore extends Store {
  Model = WorkStation
  static backendResourceName = 'work_station'

  start(code) {
    return this.wrapPendingRequestCount(this.api.post(`${this.url()}start/`, { code }))
  }
}
