import React from 'react'
import { Icon } from 'semantic-ui-react'
import { observable, computed, action } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { snakeToCamel } from 'helpers'
import moment from 'moment'

export const TYPE_ICONS = {
  environment: 'wind',
  light: 'script-type-light',
  nutrition: 'nutrition',
  water: 'water',
}

export const TYPE_COLORS = {
  environment: '#51A49A',
  light: '#7C4DFF',
  nutrition: '#A1887F',
  water: '#448AFF',
}

const TYPE_PREFIX = {
  environment: 'SE',
  light: 'SL',
  nutrition: 'SN',
  water: 'SW',
}

export class Script extends Model {
  static backendResourceName = 'script'

  static TYPES = ['environment', 'light', 'nutrition', 'water']
  static STATUSES = ['concept', 'test', 'production', 'archived']

  @observable id = null
  @observable type = 'environment'
  @observable status = 'concept'
  @observable name = ''
  @observable notes = ''

  getLabel(props = {}) {
    return super.getLabel({
      ...props,
      style: { ...props.style ?? {}, backgroundColor: TYPE_COLORS[this.type], color: '#fff' },
    })
  }

  getUrl() {
    return `/plantation/script/${this.id}/edit`
  }

  @computed get _id() {
    return (
      <>
        <Icon name={TYPE_ICONS[this.type]} />
        {TYPE_PREFIX[this.type]}
        {this.id}
      </>
    )
  }

  relations() {
    return {
      lightScript: LightScript,
      nutritionScript: NutritionScript,
      environmentScript: EnvironmentScript,
      waterScript: WaterScript,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    for (const type of Script.TYPES) {
      if (type !== this.type) {
        res[`${type}_script`] = null
      }
    }
    return res
  }

  @action toBackendAll(...args) {
    const activeCurrentRelations = this.__activeCurrentRelations

    const filterRelations = (
      Script.TYPES
      .filter((type) => type !== this.type)
      .map((type) => `${snakeToCamel(type)}Script`)
    )

    this.__activeCurrentRelations = activeCurrentRelations.filter((rel) => !filterRelations.includes(rel))

    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = activeCurrentRelations
    }
  }
}

export class ScriptStore extends Store {
  Model = Script
  static backendResourceName = 'script'
}

export class LightScript extends Model {
  static backendResourceName = 'light_script'

  @observable id = null
  @observable intensity = null
  @observable startTime = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
    }
  }

  relations() {
    return {
      schedules: LightScriptScheduleStore,
    }
  }
}

export class LightScriptSchedule extends Model {
  static backendResourceName = 'light_script_schedule'

  @observable id = null
  @observable intensity = null
  @observable startTime = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
    }
  }

  relations() {
    return {
      lightScript: LightScript,
    }
  }
}

export class LightScriptScheduleStore extends Store {
  static backendResourceName = 'light_script_schedule'
  Model = LightScriptSchedule
}

export class NutritionScript extends Model {
  static backendResourceName = 'nutrition_script'

  @observable id = null
  @observable nitrogen = null
  @observable phosphorus = null
  @observable potassium = null
  @observable calcium = null
  @observable magnesium = null
  @observable sulphur = null
}

export class EnvironmentScript extends Model {
  static backendResourceName = 'environment_script'

  static DIRECTIONS = ['a_to_b', 'b_to_a']

  @observable id = null
  @observable direction = 'a_to_b'
  @observable speed = null
  @observable tIn = null
  @observable deltaX = null
  @observable co2In = null
  @observable startTime = moment('00:00:00', 'HH:mm:ss')

  @computed get deltaT() {
    return this.deltaX
  }

  casts() {
    return {
      startTime: Casts.momentTime,
    }
  }

  relations() {
    return {
      schedules: EnvironmentScriptScheduleStore,
    }
  }
}

export class EnvironmentScriptSchedule extends Model {
  static backendResourceName = 'environment_script_schedule'

  static DIRECTIONS = ['a_to_b', 'b_to_a']

  @observable id = null
  @observable direction = 'a_to_b'
  @observable speed = null
  @observable tIn = null
  @observable deltaT = null
  @observable deltaX = null
  @observable co2In = null
  @observable startTime = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
    }
  }

  relations() {
    return {
      environmentScript: EnvironmentScript,
    }
  }
}

export class EnvironmentScriptScheduleStore extends Store {
  static backendResourceName = 'environment_script_schedule'
  Model = EnvironmentScriptSchedule
}


export class WaterScript extends Model {
  static backendResourceName = 'water_script'

  @observable id = null
  @observable ec = null
  @observable ecMin = null
  @observable ecMax = null
  @observable ecT = null
  @observable ph = null
  @observable phMin = null
  @observable phMax = null
  @observable phT = null
  @observable flowMin = null
  @observable flowMax = null
  @observable pulse = null
  @observable cycle = null

  relations() {
    return {
      nutritionScript: Script,
    }
  }
}
