import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { PerformanceStore } from './Performance'
import { Step } from './Step'
import { Detail } from './Detail'
import { LoadCarrier } from './LoadCarrier'
import { BatchType, TYPE_COMPONENT } from './BatchType'
import { BatchUsageStore } from './BatchUsage'
import { ExactGoodsDeliveryLineStore } from './ExactGoodsDeliveryLine'
import { ExactGoodsReceiptLineStore } from './ExactGoodsReceiptLine'

export class Batch extends Model {
  static backendResourceName = 'batch'

  @observable id = null
  @observable serialNumber = ''
  @observable scrapReason = null
  @observable quantity = 1
  @observable variableQuantity = false
  @observable expectedQuantity = 1
  @observable resettable = false
  @observable scrappable = false
  @observable finalizedAt = null

  // Annotations
  @observable finished = false
  @observable quantityRemaining = 1
  @observable lastPerformance = null

  @computed
  get isSubassembly() {
    return this.batchType.type === TYPE_COMPONENT
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.finished
    delete res.quantity_remaining
    delete res.resettable
    delete res.last_performance
    return res
  }

  relations() {
    return {
      productionRequest: ProductionRequest,
      lastStep: Step,
      performances: PerformanceStore,

      batchType: BatchType,
      detail: Detail,
      batchUsings: BatchUsageStore,
      batchUseds: BatchUsageStore,

      exactGoodsDeliveryLines: ExactGoodsDeliveryLineStore,
      exactGoodsReceiptLines: ExactGoodsReceiptLineStore,
      loadCarrier: LoadCarrier,
    }
  }

  casts() {
    return {
      finalizedAt: Casts.datetime,
      lastPerformance: Casts.datetime,
    }
  }
}

export class BatchStore extends Store {
  Model = Batch
  static backendResourceName = 'batch'
}
