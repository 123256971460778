import React from 'react'
import { observable, computed, reaction } from 'mobx'
import BaseAdminOverview from 'spider/semantic-ui/Admin/Overview'
import Toolbar from 'component/Toolbar'
import styled from 'styled-components'

export const EmptyMessageContainer = styled.p`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
`

function getPrefix(prefix) {
  if (prefix === '') {
    return ''
  } else {
    return `${prefix} - `
  }
}

export default class AdminOverview extends BaseAdminOverview {
  Toolbar = Toolbar

  // So we do this with a reaction instead of a computed value
  // because the base admin overview already has tabTitlePrefix as an
  // attribute and thus we sadly cannot override it with a getter
  tabTitlePrefix = getPrefix(window.viewStore.tabTitlePrefix)

  componentDidMount() {
    super.componentDidMount()
    this.tabTitlePrefixReaction = reaction(
      () => window.viewStore.tabTitlePrefix,
      (tabTitlePrefix) => {
        this.tabTitlePrefix = getPrefix(tabTitlePrefix)
        this.forceUpdate()
      }
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.tabTitlePrefixReaction()
  }

  emptyMessage = null
  emptyMessageSearch = null

  itemButtonProps = {
    primary: false,
  }

  @computed get empty() {
    return (
      !this.store.isLoading &&
      (this.store.totalPages === 0 ||
        (this.store.totalPages === 1 && this.store.currentPage === 1 && this.store.length === 0))
    )
  }

  renderOverviewTable() {
    return this.empty && this.emptyMessage ? (
      <EmptyMessageContainer>{this.emptyMessage}</EmptyMessageContainer>
    ) : (
      super.renderOverviewTable()
    )
  }

  renderPaginationControls() {
    return this.empty && this.emptyMessage ? null : super.renderPaginationControls()
  }
}

export * from 'spider/semantic-ui/Admin/Overview'
