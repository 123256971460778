import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { BillOfMaterialVersion } from './BillOfMaterialVersion'
import { Warehouse } from './Warehouse'
import { ResourceAllocationStore } from './ResourceAllocation'

export class ProductionOrder extends Model {
  static backendResourceName = 'production_order'
  static idPrefix = 'PR'
  static idColor = 'orange'
  static idIcon = 'handyman'
  static omitFields = ['unallocated', 'quantityStatus']

  @observable id = null
  @observable status = 'open'
  @observable unallocated = 0
  @observable quantityStatus = 'open'

  relations() {
    return {
      productionRequest: ProductionRequest,
      billOfMaterialVersion: BillOfMaterialVersion,
      warehouse: Warehouse,
      resourceAllocations: ResourceAllocationStore,
    }
  }

  async release() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}release/`))
      this.status = 'released'
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }
}

export class ProductionOrderStore extends Store {
  static backendResourceName = 'production_order'

  Model = ProductionOrder
}
