import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionLine } from './ProductionLine'
import { Script } from './Script'

export class ScriptAllocation extends Model {
  static backendResourceName = 'script_allocation'

  @observable id = null
  @observable rack = 1
  @observable layer = 1
  @observable position = 1
  @observable startDate = null
  @observable endDate = null

  relations() {
    return {
      plantationLine: ProductionLine,
      script: Script,
    }
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class ScriptAllocationStore extends Store {
  static backendResourceName = 'script_allocation'
  Model = ScriptAllocation
}
